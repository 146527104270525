import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { inject, Injectable } from '@angular/core';
import { StorageContextService } from '../../context/storage-context/storage-context.service';
import { UserStore } from '../../stores/user/user.store';

@Injectable({ providedIn: 'root' })
export class OptionalAuthGuard {
  private readonly storageContext = inject(StorageContextService);
  private readonly userStore = inject(UserStore);
  private readonly router = inject(Router);

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const apiKey = route.queryParamMap.get('ApiKey') || route.queryParamMap.get('apiKey');
    if (apiKey) {
      this.storageContext.setItem('access_token', apiKey.replace(/\s/g, '+'));
      try {
        await this.userStore.waitForLoading();
      } catch (e) {
        this.storageContext.removeItem('access_token');
        const queryParams = { ...route.queryParams, ApiKey: undefined, apiKey: undefined };
        await this.router.navigate(
          route.pathFromRoot
            .map(p => p.url)
            .flat()
            .map(({ path }) => path),
          { queryParams, replaceUrl: true }
        );
      }
    }
    return true;
  }
}
