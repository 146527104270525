import { Routes } from '@angular/router';
import { AuthorizationGuard } from './guards/authorization-guard/authorization.guard';
import { PricesResolver } from '../resolvers/prices-resolver/prices-resolve.service';

export const AUTH_ROUTES: Routes = [
  {
    path: 'auth',
    loadComponent: () => import('./auth.component').then(m => m.AuthComponent),
    canActivate: [AuthorizationGuard],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'sign-up',
      },
      {
        path: 'sign-in',
        loadComponent: () => import('./sign-in/sign-in.component').then(m => m.SignInComponent),
      },
      {
        path: 'sign-up/:type',
        loadComponent: () => import('./sign-up/sign-up.component').then(m => m.SignUpComponent),
        resolve: {
          prices: PricesResolver,
        },
      },
      {
        path: 'sign-up',
        loadComponent: () => import('./sign-up/sign-up.component').then(m => m.SignUpComponent),
        resolve: {
          prices: PricesResolver,
        },
      },
      {
        path: 'forgot-password',
        loadComponent: () =>
          import('./forgot-password/forgot-password.component').then(
            m => m.ForgotPasswordComponent
          ),
      },
      {
        path: 'sign-up-no-trial',
        loadComponent: () =>
          import('./sign-up-no-trial/sign-up-no-trial.component').then(
            m => m.SignUpNoTrialComponent
          ),
      },
    ],
  },
] as const;
