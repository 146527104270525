import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Media } from '../../dataset/Media';
import { filter, tap } from 'rxjs/operators';
import { MediaContextService } from '../../context/media-context/media-context.service';
import { MediaApiService } from '../../api/media-api/media-api.service';
import { UserStore } from '../../stores/user/user.store';

@Injectable({ providedIn: 'root' })
export class ProgressPhotoResolver {
  private readonly userStore = inject(UserStore);
  private readonly mediaApiService = inject(MediaApiService);
  private readonly mediaContext = inject(MediaContextService);

  resolve(): Observable<Media[]> | void {
    const photos = this.mediaContext.get();

    if ((photos && photos.length) || !this.userStore.isLoggedIn()) {
      return;
    }

    return this.mediaApiService.retrieve().pipe(
      filter(media => !!media),
      tap(media => this.mediaContext.next(media))
    );
  }
}
