import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ICertificatePrice } from '../../../../../entities/certificate-entity/interfaces/ICertificatePrice';
import { CertificateFacade } from '../../../../../entities/certificate-entity/certificate.facade';

@Injectable({ providedIn: 'root' })
export class CertificatePricesResolver {
  constructor(private certificateFacade: CertificateFacade) {}

  resolve(): Observable<ICertificatePrice[]> | void {
    if (
      this.certificateFacade.certificatePrices &&
      this.certificateFacade.certificatePrices.length
    ) {
      return;
    }

    return this.certificateFacade.loadCertificatePrices();
  }
}
