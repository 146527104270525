import { Routes } from '@angular/router';
import { PAGES_ROUTES } from './pages/pages-routes';
import { DEPRECATED_PAGES_ROUTES } from './pages/deprecated-pages-routes';
import { AUTH_ROUTES } from './auth/auth-routes';
import { DASHBOARD_ROUTES } from './dashboard/dashboard-routes';
import { GIFT_CERTIFICATE_ROUTES } from './pages/gift-certificate/gift-certificate-routes';

export const APP_ROUTES: Routes = [
  ...DASHBOARD_ROUTES,
  ...AUTH_ROUTES,
  ...GIFT_CERTIFICATE_ROUTES,
  ...PAGES_ROUTES,
  ...DEPRECATED_PAGES_ROUTES,
  {
    path: '**',
    loadComponent: () => import('./pages/404/not-found.component').then(m => m.NotFoundComponent),
  },
] as const;
