import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageContextService } from '../../context/storage-context/storage-context.service';

@Injectable({ providedIn: 'root' })
export class AccessTokenGuard {
  constructor(private storageContext: StorageContextService) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const apiKey = route.queryParamMap.get('ApiKey') || route.queryParamMap.get('apiKey');

    if (apiKey) {
      this.storageContext.setItem('access_token', apiKey.replace(/\s/g, '+'));
    }

    return true;
  }
}
