import { Injectable } from '@angular/core';
import {
  DictionaryService,
  TitleAndDescription,
} from '../../../services/dictionary/dictionary.service';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class SEOService {
  constructor(
    private titleService: Title,
    private metaService: Meta,
    private dictionary: DictionaryService
  ) {}

  setTitleAndDescription(location: string): void {
    const { title, description } = this.dictionary.SEOTitlesAndDescriptions[
      location
    ] as TitleAndDescription;

    this.titleService.setTitle(title);

    this.metaService.updateTag({
      name: 'title',
      content: title,
    });

    this.metaService.updateTag({
      property: 'og:title',
      content: title,
    });

    this.metaService.updateTag({
      name: 'twitter:title',
      content: title,
    });

    this.metaService.updateTag({
      name: 'description',
      content: description,
    });

    this.metaService.updateTag({
      property: 'og:description',
      content: description,
    });

    this.metaService.updateTag({
      name: 'twitter:description',
      content: description,
    });
  }
}
