import { Route } from '@angular/router';
import { QuizGuard } from '../guards/quiz-guard/quiz.guard';
import { QuizImagesResolver } from '../resolvers/quiz-images-resolver/quiz-images.resolver';
import { DraftUserResolveService } from '../resolvers/draft-user-resolver/draft-user-resolve.service';
import { PricesResolver } from '../resolvers/prices-resolver/prices-resolve.service';
import { PackagesResolver } from '../resolvers/packages-resolver/packages-resolve.service';
import { MetricsResolver } from '../resolvers/metrics/metrics.resolver';
import { RedirectGuard } from '../guards/redirect-guard/redirect.guard';
import { ProgramGuard } from '../guards/program-quard/program.guard';

export const DEPRECATED_PAGES_ROUTES: Route[] = [
  { path: 'quiz19', loadChildren: () => import('./age/age.module').then(m => m.AgeModule) },
  {
    path: 'quiz17',
    loadChildren: () => import('./tinder/tinder.module').then(m => m.TinderModule),
  },
  {
    path: 'special-cancelled-trial',
    loadChildren: () =>
      import('./special-offer-no-trial/special-offer-no-trial.module').then(
        m => m.SpecialOfferNoTrialModule
      ),
  },
  // {
  //   path: 'sweatcoin',
  //   loadChildren: () =>
  //     import('./long-trial-landing/long-trial-landing.module').then(
  //       m => m.LongTrialLandingModule
  //     ),
  //   resolve: {
  //     user: DraftUserResolveService,
  //   },
  //   data: {
  //     prefix: 'sweat-coins',
  //   },
  // },
  // {
  //   path: 'delight',
  //   loadChildren: () =>
  //     import('./long-trial-landing/long-trial-landing.module').then(
  //       m => m.LongTrialLandingModule
  //     ),
  //   resolve: {
  //     user: DraftUserResolveService,
  //   },
  //   data: {
  //     prefix: 'delight',
  //   },
  // },
  {
    path: 'unplug',
    loadChildren: () =>
      import('./long-trial-landing/long-trial-landing.module').then(m => m.LongTrialLandingModule),
    resolve: {
      user: DraftUserResolveService,
    },
    data: {
      onboarding: 'unplug',
    },
  },
  {
    path: 'unplug-business',
    loadChildren: () =>
      import('./long-trial-landing/long-trial-landing.module').then(m => m.LongTrialLandingModule),
    resolve: {
      user: DraftUserResolveService,
    },
    data: {
      onboarding: 'unplug-business',
    },
  },
  {
    path: 'unplug-internal',
    loadChildren: () =>
      import('./long-trial-landing/long-trial-landing.module').then(m => m.LongTrialLandingModule),
    resolve: {
      user: DraftUserResolveService,
    },
    data: {
      onboarding: 'unplug-internal',
    },
  },
  {
    path: 'unplug-business-internal',
    loadChildren: () =>
      import('./long-trial-landing/long-trial-landing.module').then(m => m.LongTrialLandingModule),
    resolve: {
      user: DraftUserResolveService,
    },
    data: {
      onboarding: 'unplug-business-internal',
    },
  },
  // {
  //   path: 'sweatcoin-quiz',
  //   loadChildren: () => import('./quiz-sweatcoins/quiz.module').then(m => m.Quiz3Module),
  //   canActivate: [QuizGuard],
  //   resolve: {
  //     images: QuizImagesResolver,
  //   },
  //   data: {
  //     prefix: 'sweat-coins-quiz',
  //     startsWith: 'app-age',
  //     hideCounter: true,
  //   },
  // },
  {
    path: 'vip',
    loadChildren: () => import('./vip-program/vip-program.module').then(m => m.VipProgramModule),
  },
  {
    path: 'app/competition/new',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: 'https://stretchitapp.com/competition',
    },
  },
  {
    path: 'quiz17',
    loadChildren: () => import('./tinder/tinder.module').then(m => m.TinderModule),
  },
  {
    path: 'special-cancelled-trial',
    loadChildren: () =>
      import('./special-offer-no-trial/special-offer-no-trial.module').then(
        m => m.SpecialOfferNoTrialModule
      ),
  },
  {
    path: 'active-sweat-program',
    loadChildren: () =>
      import('./active-sweat-program/active-sweat-program.module').then(
        m => m.ActiveSweatProgramModule
      ),
  },
  {
    path: 'quiz24',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz24',
      hideCounter: true,
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz30',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz30',
      hideCounter: true,
      dropLast: 3,
      startsWith: 'full__training_minutes',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz31',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz31',
      hideCounter: true,
      dropLast: 3,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz37',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz37',
      hideCounter: true,
      dropLast: 3,
      startsWith: 'full__training_minutes',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz36',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz36',
      hideCounter: true,
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz28',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz28',
      hideCounter: true,
      dropLast: 3,
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz29',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz29',
      hideCounter: true,
      dropLast: 3,
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz38',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz38',
      hideCounter: true,
      dropLast: 3,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz45',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz45',
      hideCounter: true,
      dropLast: 3,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz44',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz44',
      hideCounter: true,
      dropLast: 3,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz49',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz49',
      hideCounter: true,
      dropLast: 3,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz39',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz39',
      hideCounter: true,
      dropLast: 3,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz56',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz56',
      hideCounter: true,
      dropLast: 3,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz57',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz57',
      hideCounter: true,
      dropLast: 3,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz40',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz40',
      hideCounter: true,
      dropLast: 3,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz41',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz41',
      hideCounter: true,
      dropLast: 3,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz42',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz42',
      hideCounter: true,
      dropLast: 3,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz43',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz43',
      hideCounter: true,
      dropLast: 3,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz46',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz46',
      hideCounter: true,
      dropLast: 3,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz47',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz47',
      hideCounter: true,
      dropLast: 3,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz48',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz48',
      hideCounter: true,
      dropLast: 3,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz50',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz50',
      hideCounter: true,
      dropLast: 3,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz64',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz64',
      hideCounter: true,
      dropLast: 3,
      startsWith: 'app-pole-dance',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz65',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz65',
      hideCounter: true,
      dropLast: 3,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz66',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz66',
      hideCounter: true,
      dropLast: 3,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz67',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz67',
      hideCounter: true,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz67.1',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz67.1',
      hideCounter: true,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz67.2',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz67.2',
      hideCounter: true,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz67.3',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz67.3',
      hideCounter: true,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz67.4',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz67.4',
      hideCounter: true,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz67.5',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz67.5',
      hideCounter: true,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz133',
    loadComponent: () =>
      import('./individual-program-quiz/quiz.component').then(c => c.QuizComponent),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz133',
      hideCounter: true,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz68',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz68',
      hideCounter: true,
      dropLast: 3,
      startsWith: 'app-age-unisex',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz78',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz78',
      hideCounter: true,
      showTopRated: true,
      showRating: true,
      dropLast: 3,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz79',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz79',
      hideCounter: true,
      showTopRated: true,
      showRating: true,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz76',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz76',
      hideCounter: true,
      dropLast: 3,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz77',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz77',
      hideCounter: true,
      dropLast: 3,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz83',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz83',
      hideCounter: true,
      showTopRated: true,
      showRating: true,
      dropLast: 3,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz84',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz84',
      hideCounter: true,
      showTopRated: true,
      showRating: true,
      dropLast: 3,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz85',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz85',
      hideCounter: true,
      showTopRated: true,
      showRating: true,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz86',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz86',
      hideCounter: true,
      showTopRated: true,
      showRating: true,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz88',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz88',
      hideCounter: true,
      dropLast: 3,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz89',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz89',
      hideCounter: true,
      showTopRated: true,
      showRating: true,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz90',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz90',
      hideCounter: true,
      showTopRated: true,
      showRating: true,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz74',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz74',
      hideCounter: true,
      dropLast: 3,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz75',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz75',
      hideCounter: true,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz81',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz81',
      hideCounter: true,
      dropLast: 3,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz82',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz82',
      hideCounter: true,
      dropLast: 3,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz91',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz91',
      hideCounter: true,
      showTopRated: true,
      showRating: true,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz95',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz95',
      hideCounter: true,
      showTopRated: true,
      showRating: true,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz96',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz96',
      hideCounter: true,
      showTopRated: true,
      showRating: true,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz94',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz94',
      hideCounter: true,
      showTopRated: true,
      showRating: true,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz93',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz93',
      hideCounter: true,
      showTopRated: true,
      showRating: true,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz99',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz99',
      hideCounter: true,
      showTopRated: true,
      showRating: true,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz100',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz100',
      hideCounter: true,
      showTopRated: true,
      showRating: true,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz101',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz101',
      hideCounter: true,
      showTopRated: true,
      showRating: true,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz102',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz102',
      hideCounter: true,
      showTopRated: true,
      showRating: true,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz106',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz106',
      hideCounter: true,
      startsWith: 'app-age-men',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz113',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz113',
      hideCounter: true,
      showTopRated: true,
      showRating: true,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz114',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz114',
      hideCounter: true,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz115',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz115',
      hideCounter: true,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz116',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz116',
      hideCounter: true,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz118',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz118',
      hideCounter: true,
      showTopRated: true,
      showRating: true,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz118.1',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz118.1',
      hideCounter: true,
      showTopRated: true,
      showRating: true,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz118.2',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz118.2',
      hideCounter: true,
      showTopRated: true,
      showRating: true,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz120',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz120',
      hideCounter: true,
      showTopRated: true,
      showRating: true,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz69',
    pathMatch: 'full',
    redirectTo: 'quiz69/en',
  },
  {
    path: 'quiz69/:lang',
    loadChildren: () => import('./long-quiz/quiz.module').then(m => m.LongQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz69',
      hideCounter: true,
      dropLast: 3,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    loadChildren: () =>
      import('./personalized-program/personalized-program.module').then(
        m => m.PersonalizedProgramModule
      ),
    path: 'personalized-program',
    canActivate: [ProgramGuard],
    resolve: {
      metrics: MetricsResolver,
      user: DraftUserResolveService,
      prices: PricesResolver,
      packages: PackagesResolver,
    },
  },
  {
    loadChildren: () =>
      import('./personalized-program/personalized-program.module').then(
        m => m.PersonalizedProgramModule
      ),
    path: 'youtube-giveaway',
    resolve: {
      metrics: MetricsResolver,
      user: DraftUserResolveService,
      prices: PricesResolver,
      packages: PackagesResolver,
    },
    data: {
      prefix: 'youtube',
    },
  },
  {
    loadChildren: () =>
      import('./personalized-program-2/personalized-program-2.module').then(
        m => m.PersonalizedProgram2Module
      ),
    path: 'personalized-program-3',
    // canActivate: [ProgramGuard],
    resolve: {
      metrics: MetricsResolver,
      user: DraftUserResolveService,
      prices: PricesResolver,
      packages: PackagesResolver,
    },
    data: {
      variant: 'bloggers3',
      prefix: 'influencer-flow-3',
    },
  },
  {
    loadChildren: () =>
      import('./personalized-program-2/personalized-program-2.module').then(
        m => m.PersonalizedProgram2Module
      ),
    path: 'personalized-program-2',
    // canActivate: [ProgramGuard],
    resolve: {
      metrics: MetricsResolver,
      user: DraftUserResolveService,
      prices: PricesResolver,
      packages: PackagesResolver,
    },
    data: {
      variant: 'bloggers2',
      prefix: 'influencer-flow-2',
    },
  },
  {
    loadChildren: () =>
      import('./personalized-program-2/personalized-program-2.module').then(
        m => m.PersonalizedProgram2Module
      ),
    path: 'influencer_flow/1',
    // canActivate: [ProgramGuard],
    resolve: {
      metrics: MetricsResolver,
      user: DraftUserResolveService,
      prices: PricesResolver,
      packages: PackagesResolver,
    },
    data: {
      variant: 'bloggers1',
      prefix: 'influencer-flow-1',
    },
  },
  {
    loadChildren: () =>
      import('./personalized-program-2/personalized-program-2.module').then(
        m => m.PersonalizedProgram2Module
      ),
    path: 'influencer_flow/kize',
    // canActivate: [ProgramGuard],
    resolve: {
      metrics: MetricsResolver,
      user: DraftUserResolveService,
      prices: PricesResolver,
      packages: PackagesResolver,
    },
    data: {
      variant: 'kize',
      prefix: 'influencer-flow-kize',
    },
  },
  {
    loadChildren: () => import('./gift-page/gift-page.module').then(m => m.GiftPageModule),
    path: 'personal-gift',
    resolve: {
      user: DraftUserResolveService,
      prices: PricesResolver,
      packages: PackagesResolver,
      metrics: MetricsResolver,
    },
    data: {
      prefix: 'envoy',
      paywallName: 'envoy',
    },
  },
  {
    path: 'quiz',
    loadChildren: () => import('./quiz/quiz.module').then(m => m.QuizModule),
    canActivate: [QuizGuard],
    resolve: {
      images: QuizImagesResolver,
    },
    data: {
      prefix: 'quiz1',
    },
  },
  {
    path: 'quiz2',
    loadChildren: () => import('./quiz-2/quiz.module').then(m => m.Quiz2Module),
    canActivate: [QuizGuard],
    resolve: {
      images: QuizImagesResolver,
    },
    data: { prefix: 'quiz2' },
  },
  {
    path: 'quiz6',
    loadChildren: () => import('./quiz-2/quiz.module').then(m => m.Quiz2Module),
    canActivate: [QuizGuard],
    resolve: {
      images: QuizImagesResolver,
    },
    data: { prefix: 'quiz6' },
  },
  {
    path: 'quiz9',
    loadChildren: () => import('./quiz-2/quiz.module').then(m => m.Quiz2Module),
    canActivate: [QuizGuard],
    resolve: {
      images: QuizImagesResolver,
    },
    data: { prefix: 'quiz9' },
  },
  {
    path: 'quiz15',
    loadChildren: () => import('./quiz-2/quiz.module').then(m => m.Quiz2Module),
    canActivate: [QuizGuard],
    resolve: {
      images: QuizImagesResolver,
    },
    data: { prefix: 'quiz15' },
  },
  {
    path: 'quiz14',
    loadChildren: () => import('./quiz-2/quiz.module').then(m => m.Quiz2Module),
    canActivate: [QuizGuard],
    resolve: {
      images: QuizImagesResolver,
    },
    data: { prefix: 'quiz14' },
  },
  {
    path: 'quiz-influencer-2',
    loadChildren: () => import('./quiz-2/quiz.module').then(m => m.Quiz2Module),
    canActivate: [QuizGuard],
    resolve: {
      images: QuizImagesResolver,
    },
    data: { variant: 'bloggers2', prefix: 'influencer-flow-2' },
  },
  {
    path: 'quiz-influencer-3',
    loadChildren: () => import('./quiz-2/quiz.module').then(m => m.Quiz2Module),
    resolve: {
      images: QuizImagesResolver,
    },
    data: { variant: 'bloggers3', prefix: 'influencer-flow-3' },
  },
  {
    path: 'quiz18',
    loadChildren: () => import('./quiz-2/quiz.module').then(m => m.Quiz2Module),
    canActivate: [QuizGuard],
    resolve: {
      images: QuizImagesResolver,
    },
    data: { prefix: 'quiz18', progressBarMode: 'dashed' },
  },
  {
    path: 'quiz16',
    loadChildren: () => import('./quiz-2/quiz.module').then(m => m.Quiz2Module),
    canActivate: [QuizGuard],
    resolve: {
      images: QuizImagesResolver,
    },
    data: { prefix: 'quiz16' },
  },
  {
    path: 'quiz22',
    loadChildren: () => import('./quiz-2/quiz.module').then(m => m.Quiz2Module),
    canActivate: [QuizGuard],
    resolve: {
      images: QuizImagesResolver,
    },
    data: { prefix: 'quiz22' },
  },
  {
    path: 'quiz11',
    loadChildren: () => import('./quiz-2/quiz.module').then(m => m.Quiz2Module),
    canActivate: [QuizGuard],
    resolve: {
      images: QuizImagesResolver,
    },
    data: { prefix: 'quiz11', smiles: true },
  },
  {
    path: 'quiz21',
    loadChildren: () => import('./quiz-2/quiz.module').then(m => m.Quiz2Module),
    canActivate: [QuizGuard],
    resolve: {
      images: QuizImagesResolver,
    },
    data: { prefix: 'quiz21', startFrom: 'full__training_minutes' },
  },
  {
    path: 'quiz7',
    loadChildren: () => import('./quiz-2/quiz.module').then(m => m.Quiz2Module),
    canActivate: [QuizGuard],
    resolve: {
      images: QuizImagesResolver,
    },
    data: { prefix: 'quiz7' },
  },
  {
    path: 'quiz35',
    loadChildren: () => import('./quiz-2/quiz.module').then(m => m.Quiz2Module),
    canActivate: [QuizGuard],
    resolve: {
      images: QuizImagesResolver,
    },
    data: { prefix: 'quiz35' },
  },
  {
    path: 'quiz13',
    loadChildren: () => import('./quiz-2/quiz.module').then(m => m.Quiz2Module),
    canActivate: [QuizGuard],
    resolve: {
      images: QuizImagesResolver,
    },
    data: { prefix: 'quiz13', animatedComplete: true, startFrom: 'complete' },
  },
  {
    path: 'quiz5',
    loadChildren: () => import('./quiz-2-no-images/quiz.module').then(m => m.Quiz2NoImagesModule),
    canActivate: [QuizGuard],
    resolve: {
      images: QuizImagesResolver,
    },
    data: {
      prefix: 'quiz5',
    },
  },
  {
    path: 'quiz34',
    loadChildren: () => import('./quiz-2-no-images/quiz.module').then(m => m.Quiz2NoImagesModule),
    canActivate: [QuizGuard],
    resolve: {
      images: QuizImagesResolver,
    },
    data: {
      prefix: 'quiz34',
    },
  },
  {
    path: 'quiz10',
    loadChildren: () =>
      import('./quiz-2-illustrations/quiz.module').then(m => m.Quiz2IllustrationsModule),
    canActivate: [QuizGuard],
    resolve: {
      images: QuizImagesResolver,
    },
    data: { prefix: 'quiz10', startFrom: 'intro' },
  },
  {
    path: 'quiz3',
    loadChildren: () => import('./quiz-3/quiz.module').then(m => m.Quiz3Module),
    canActivate: [QuizGuard],
    resolve: {
      images: QuizImagesResolver,
    },
    data: {
      prefix: 'quiz3',
    },
  },
  {
    path: 'quiz4',
    loadChildren: () => import('./quiz-3/quiz.module').then(m => m.Quiz3Module),
    canActivate: [QuizGuard],
    data: {
      dropLast: 3,
      prefix: 'quiz4',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz25',
    loadChildren: () => import('./quiz-3/quiz.module').then(m => m.Quiz3Module),
    canActivate: [QuizGuard],
    resolve: {
      images: QuizImagesResolver,
    },
    data: {
      prefix: 'quiz25',
    },
  },
  {
    path: 'quiz26',
    loadChildren: () => import('./quiz-3/quiz.module').then(m => m.Quiz3Module),
    canActivate: [QuizGuard],
    data: {
      dropLast: 3,
      prefix: 'quiz26',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz33',
    loadChildren: () => import('./quiz-3/quiz.module').then(m => m.Quiz3Module),
    canActivate: [QuizGuard],
    data: {
      dropLast: 3,
      prefix: 'quiz33',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz32',
    loadChildren: () => import('./quiz-3/quiz.module').then(m => m.Quiz3Module),
    canActivate: [QuizGuard],
    resolve: {
      images: QuizImagesResolver,
    },
    data: {
      prefix: 'quiz32',
    },
  },
  {
    path: 'quiz27',
    loadChildren: () => import('./quiz-3/quiz.module').then(m => m.Quiz3Module),
    canActivate: [QuizGuard],
    resolve: {
      images: QuizImagesResolver,
    },
    data: {
      dropLast: 3,
      prefix: 'quiz27',
      progressBarMode: 'dashed',
    },
  },
  {
    path: 'quiz58',
    loadChildren: () => import('./quiz-3/quiz.module').then(m => m.Quiz3Module),
    canActivate: [QuizGuard],
    resolve: {
      images: QuizImagesResolver,
    },
    data: {
      prefix: 'quiz58',
    },
  },
  {
    path: 'quiz55',
    loadChildren: () => import('./quiz-3/quiz.module').then(m => m.Quiz3Module),
    canActivate: [QuizGuard],
    resolve: {
      images: QuizImagesResolver,
    },
    data: {
      prefix: 'quiz55',
      progressBarMode: 'dashed',
    },
  },
  {
    path: 'quiz59',
    loadChildren: () => import('./quiz-3/quiz.module').then(m => m.Quiz3Module),
    canActivate: [QuizGuard],
    resolve: {
      images: QuizImagesResolver,
    },
    data: {
      prefix: 'quiz59',
    },
  },
  {
    path: 'quiz60',
    loadChildren: () => import('./quiz-3/quiz.module').then(m => m.Quiz3Module),
    canActivate: [QuizGuard],
    resolve: {
      images: QuizImagesResolver,
    },
    data: {
      prefix: 'quiz60',
      startsWith: 'app-age',
      progressBarMode: 'dashed',
    },
  },
  {
    path: 'quiz61',
    loadChildren: () => import('./quiz-3/quiz.module').then(m => m.Quiz3Module),
    canActivate: [QuizGuard],
    resolve: {
      images: QuizImagesResolver,
    },
    data: {
      prefix: 'quiz61',
      startsWith: 'app-age',
      progressBarMode: 'dashed',
    },
  },
  {
    path: 'quiz62',
    loadChildren: () => import('./quiz-3/quiz.module').then(m => m.Quiz3Module),
    canActivate: [QuizGuard],
    resolve: {
      images: QuizImagesResolver,
    },
    data: {
      prefix: 'quiz62',
      startsWith: 'app-age',
      progressBarMode: 'dashed',
    },
  },
  {
    path: 'quiz63',
    loadChildren: () => import('./quiz-3/quiz.module').then(m => m.Quiz3Module),
    canActivate: [QuizGuard],
    resolve: {
      images: QuizImagesResolver,
    },
    data: {
      prefix: 'quiz63',
      startsWith: 'app-age',
      progressBarMode: 'dashed',
    },
  },
  {
    path: 'quiz107',
    loadChildren: () => import('./quiz-3/quiz.module').then(m => m.Quiz3Module),
    canActivate: [QuizGuard],
    resolve: {
      images: QuizImagesResolver,
    },
    data: {
      prefix: 'quiz107',
      startsWith: 'app-age',
      hideCounter: true,
      showTopRated: true,
      showRating: true,
    },
  },
  // {
  //   path: 'sweatcoin-quiz',
  //   loadChildren: () => import('./quiz-sweatcoins/quiz.module').then(m => m.Quiz3Module),
  //   canActivate: [QuizGuard],
  //   resolve: {
  //     images: QuizImagesResolver,
  //   },
  //   data: {
  //     prefix: 'sweat-coins-quiz',
  //     startsWith: 'app-age',
  //     hideCounter: true,
  //   },
  // },
  {
    path: 'quiz51',
    loadChildren: () => import('./quiz-51/quiz.module').then(m => m.Quiz51Module),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz51',
      hideCounter: true,
      dropLast: 3,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz52',
    loadChildren: () => import('./quiz-52/quiz.module').then(m => m.Quiz52Module),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz52',
      hideCounter: true,
      dropLast: 3,
      startsWith: 'start',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz53',
    loadChildren: () => import('./quiz-53/quiz.module').then(m => m.Quiz53Module),
    canActivate: [QuizGuard],
    data: {
      prefix: 'quiz53',
      hideCounter: true,
      dropLast: 3,
      startsWith: 'start',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz54',
    loadChildren: () => import('./quiz-54/quiz.module').then(m => m.Quiz54Module),
    canActivate: [QuizGuard],
    resolve: {
      images: QuizImagesResolver,
    },
    data: {
      prefix: 'quiz54',
      progressBarMode: 'dashed',
    },
  },
  {
    path: 'quiz-active-sweat',
    loadChildren: () =>
      import('./weight-loss-quiz/weight-loss-quiz.module').then(m => m.WeightLossQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'active-sweat',
      hideCounter: true,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz-active-sweat-3',
    loadChildren: () =>
      import('./weight-loss-quiz/weight-loss-quiz.module').then(m => m.WeightLossQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'active-sweat3',
      hideCounter: true,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz-active-sweat-2',
    loadChildren: () =>
      import('./weight-loss-quiz/weight-loss-quiz.module').then(m => m.WeightLossQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'active-sweat2',
      hideCounter: true,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
  {
    path: 'quiz-active-sweat-4',
    loadChildren: () =>
      import('./weight-loss-quiz/weight-loss-quiz.module').then(m => m.WeightLossQuizModule),
    canActivate: [QuizGuard],
    data: {
      prefix: 'active-sweat4',
      hideCounter: true,
      startsWith: 'app-age',
    },
    resolve: {
      images: QuizImagesResolver,
    },
  },
] as const;
