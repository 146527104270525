import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { CertificateFacade } from '../../../../../entities/certificate-entity/certificate.facade';
import { catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ICertificate } from '../../../../../entities/certificate-entity/interfaces/ICertificate';
import { NavigationService } from '../../../../../core/services/navigation/navigation.service';
import { ErrorService } from '../../../../../core/services/error/error.service';

@Injectable({ providedIn: 'root' })
export class CertificateResolver {
  constructor(
    private errorService: ErrorService,
    private certificateFacade: CertificateFacade,
    private navigationService: NavigationService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<ICertificate | null> {
    const code = route.queryParams['cert'];
    return this.certificateFacade.loadCertificateByCode(code).pipe(
      catchError(() => {
        this.errorService.alert(`Gift certificate code "${code ? code : ''}" is invalid`);
        this.navigationService.navigateByUrl(`/app/home`);
        return of(null);
      })
    );
  }
}
