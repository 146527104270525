import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { StorageContextService } from '../../context/storage-context/storage-context.service';

@Injectable({ providedIn: 'root' })
export class LicenseResolver implements Resolve<any> {
  constructor(
    private storageContext: StorageContextService,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    this.storageContext.setItem('license', route.params['id']);

    if (this.storageContext.getItem('access_token')) {
      this.router.navigate(['app', 'home']);
    } else {
      this.router.navigate(['auth', 'sign-in']);
    }

    return EMPTY;
  }
}
