import { Route } from '@angular/router';
import { ProgramGuard } from '../guards/program-quard/program.guard';
import { InfluencersMatcher } from '../shared/matchers/influencers.matcher';
import { MetricsResolver } from '../resolvers/metrics/metrics.resolver';
import { DraftUserResolveService } from '../resolvers/draft-user-resolver/draft-user-resolve.service';
import { PricesResolver } from '../resolvers/prices-resolver/prices-resolve.service';
import { PackagesResolver } from '../resolvers/packages-resolver/packages-resolve.service';
import { SalePopupGuard } from './sale-popup-mobile/guards/sale-popup-guard';

export const PAGES_ROUTES: Route[] = [
  {
    path: 'join',
    loadChildren: () =>
      import('./join-challenge/join-challenge.module').then(m => m.JoinChallengeModule),
  },
  {
    path: 'quiz-results',
    loadComponent: () =>
      import('./quiz-results/quiz-results.component').then(c => c.QuizResultsComponent),
  },
  {
    path: 'get-program',
    loadComponent: () =>
      import('./program-landing/program-landing.component').then(c => c.ProgramLandingComponent),
    canActivate: [ProgramGuard],
  },
  {
    path: '40off',
    loadChildren: () => import('./sale-landing/sale-landing.module').then(m => m.SaleLandingModule),
  },
  {
    matcher: InfluencersMatcher,
    loadComponent: () =>
      import('./influencers/influencers.component').then(c => c.InfluencersComponent),
    resolve: {
      metrics: MetricsResolver,
      user: DraftUserResolveService,
      prices: PricesResolver,
      packages: PackagesResolver,
    },
    data: {
      prefix: 'influencers',
      // paywallName: 'influencers',
    },
  },
  {
    path: '30daysfree',
    loadComponent: () =>
      import('./program-landing/program-landing.component').then(c => c.ProgramLandingComponent),
    data: {
      cid: '30daysfree',
    },
    canActivate: [],
  },
  {
    path: '1monthfree',
    loadComponent: () =>
      import('./program-landing/program-landing.component').then(c => c.ProgramLandingComponent),
    data: {
      cid: '1monthfree',
    },
    canActivate: [],
  },
  {
    path: 'nextjump',
    loadComponent: () =>
      import('./program-landing/program-landing.component').then(c => c.ProgramLandingComponent),
    data: {
      cid: '1monthfree',
    },
    canActivate: [],
  },
  {
    path: 'sale-popup-mobile',
    loadComponent: () =>
      import('./sale-popup-mobile/sale-popup-mobile.component').then(
        c => c.SalePopupMobileComponent
      ),
    canActivate: [SalePopupGuard],
  },
  {
    path: 'thankyou',
    loadComponent: () =>
      import('./paypal-thankyou/paypal-thankyou.component').then(c => c.PaypalThankyouComponent),
  },
] as const;
