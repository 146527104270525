import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LessonsApiService } from '../../api/lessons-api/lessons-api.service';
import { Lesson } from '../../dataset/Lesson';
import { filter, tap } from 'rxjs/operators';
import { LessonsContextService } from '../../context/lessons-context/lessons-context.service';

@Injectable({ providedIn: 'root' })
export class AvailableClassesResolver {
  constructor(
    private lessonsApiService: LessonsApiService,
    private lessonsContext: LessonsContextService
  ) {}

  resolve(): Observable<Lesson[]> | void {
    const availableLessons = this.lessonsContext.getAvailableLessons();

    if (availableLessons && availableLessons.length) {
      return;
    }

    return this.lessonsApiService.getAvailableLessons().pipe(
      filter(l => !!l),
      tap(lessons => this.lessonsContext.nextAvailableLessons(lessons))
    );
  }
}
