import { ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Lesson } from '../../dataset/Lesson';
import { LessonsApiService } from '../../api/lessons-api/lessons-api.service';
import { LessonsContextService } from '../../context/lessons-context/lessons-context.service';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PackageLessonsResolver {
  constructor(
    private lessonsContext: LessonsContextService,
    private lessonsApiService: LessonsApiService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Lesson[] | undefined> {
    const lessons = this.lessonsContext.get();
    const id = route.params['package_id'];

    if (lessons && lessons[id]) {
      return of(lessons[id]);
    }

    return this.lessonsApiService.getLessonsForPackage(id).pipe(
      tap(value => {
        const currentValue = this.lessonsContext.get();
        this.lessonsContext.next({
          ...currentValue,
          [id]: value,
        });
      })
    );
  }
}
