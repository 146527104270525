import { Routes } from '@angular/router';
import { AuthGuard } from '../guards/auth-guard/auth-guard.service';
import { UserResolver } from '../resolvers/user-resolver/user-resolve.service';
import { PackagesResolver } from '../resolvers/packages-resolver/packages-resolve.service';
import { LessonResolver } from '../resolvers/lesson-resolver/lesson-resolve.service';
import { OptionalAuthGuard } from '../guards/optional-auth-guard/optional-auth-guard';
import { ChallengesResolver } from '../resolvers/challenges-resolver/challenges-resolve.service';
import { PackageLessonsResolver } from '../resolvers/package-lessons-resolver/package-lessons-resolve.service';
import { PricesResolver } from '../resolvers/prices-resolver/prices-resolve.service';
import { DailyLessonResolver } from '../resolvers/daily-lesson-resolver/daily-lesson.resolver';
import { NewClassesResolver } from '../resolvers/new-classes-resolver/new-classes.resolver';
import { ActualChallengesResolver } from '../resolvers/actual-challenges-resolver/actual-challenges.resolver';
import { ProgressPhotoResolver } from '../resolvers/progress-photo-resolver/progress-photo.resolver';
import { SubscriptionsResolver } from '../resolvers/subscription-resolver/subscriptions.resolver';
import { AvailableClassesResolver } from '../resolvers/available-classes/available-classes.resolver';
import { FreeClassesResolver } from '../resolvers/free-classes/free-classes.resolver';
import { FavoriteClassesResolver } from '../resolvers/favorite-classes-resolver/favorite-classes-resolver';
import { AccessTokenGuard } from '../guards/access-token-quard/access-token.guard';
import { PageResolverService } from '../resolvers/page-resolver/page-resolve.service';
import { SaleGuard } from '../guards/sale-guard/sale.guard';
import { SaleMatcher } from '../shared/matchers/sale.matcher';
import { WelcomeGuard } from '../guards/welcome-guard/welcome.guard';
import { LicenseResolver } from '../resolvers/license/license.resolver';

export const DASHBOARD_ROUTES: Routes = [
  {
    path: '',
    redirectTo: '/app/home',
    pathMatch: 'full',
  },
  {
    path: '',
    loadComponent: () => import('./dashboard.component').then(m => m.DashboardComponent),
    resolve: {
      user: UserResolver,
      packages: PackagesResolver,
      prices: PricesResolver,
    },
    data: {
      disableSalePopup: true,
    },
    children: [
      {
        path: 'influencer_flow/2',
        loadComponent: () => import('./redeem/redeem.component').then(m => m.RedeemComponent),
        data: {
          prefix: 'influencer-flow-2',
        },
      },
      {
        path: 'influencer_flow/3',
        loadComponent: () => import('./redeem/redeem.component').then(m => m.RedeemComponent),
        data: {
          variant: 'bloggers3',
          prefix: 'influencer-flow-3',
        },
      },
    ],
  },
  {
    path: 'app',
    loadComponent: () => import('./dashboard.component').then(m => m.DashboardComponent),
    canActivate: [AccessTokenGuard],
    resolve: {
      user: UserResolver,
      packages: PackagesResolver,
      prices: PricesResolver,
      subscriptions: SubscriptionsResolver,
    },
    children: [
      {
        path: 'sales/popup',
        redirectTo: '/app/packages?action=upgrade',
      },
      {
        path: 'challenges',
        loadChildren: () =>
          import('../dashboard/challenges/challenges.module').then(m => m.ChallengesModule),
        canActivate: [OptionalAuthGuard],
        resolve: {
          challenges: ChallengesResolver,
        },
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('../dashboard/profile/profile.module').then(m => m.ProfileModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'available-classes',
        loadComponent: () =>
          import('./free-classes/free-classes.component').then(m => m.FreeClassesComponent),
        resolve: {
          availableClasses: AvailableClassesResolver,
        },
      },
      {
        path: 'free-classes',
        loadComponent: () =>
          import('./free-classes/free-classes.component').then(m => m.FreeClassesComponent),
        resolve: {
          freeClasses: FreeClassesResolver,
        },
      },
      {
        path: 'new-classes',
        loadComponent: () =>
          import('./new-classes/new-classes.component').then(m => m.NewClassesComponent),
        resolve: {
          newClasses: NewClassesResolver,
        },
      },
      {
        path: 'favorite-classes',
        loadComponent: () =>
          import('./favorite-classes/favorite-classes.component').then(
            m => m.FavoriteClassesComponent
          ),
        resolve: {
          availableClasses: FavoriteClassesResolver,
        },
      },
      {
        path: 'progress-photos',
        loadComponent: () =>
          import('../dashboard/photos/photos.component').then(m => m.PhotosComponent),
        canActivate: [AuthGuard],
        resolve: {
          progressPhoto: ProgressPhotoResolver,
        },
      },
      {
        path: 'packages',
        loadComponent: () =>
          import('../dashboard/packages/components/packages/packages.component').then(
            m => m.PackagesComponent
          ),
        canActivate: [OptionalAuthGuard],
        resolve: {
          actualChallenges: ActualChallengesResolver,
        },
      },
      {
        path: 'packages/:package_id',
        loadComponent: () =>
          import('./packages/components/package/package.component').then(m => m.PackageComponent),
        resolve: {
          lessons: PackageLessonsResolver,
        },
      },
      {
        path: 'packages/:package_id/about',
        loadComponent: () =>
          import('./packages/components/package-about/package-about.component').then(
            m => m.PackageAboutComponent
          ),
      },
      {
        path: 'packages/:package_id/lesson/:lesson_id',
        loadComponent: () =>
          import('../dashboard/packages/components/lesson/lesson.component').then(
            m => m.LessonComponent
          ),
        resolve: {
          lessons: PackageLessonsResolver,
          lesson: LessonResolver,
        },
      },
      {
        path: 'home',
        loadComponent: () => import('./home/home.component').then(m => m.HomeComponent),
        resolve: {
          progressPhoto: ProgressPhotoResolver,
          daily: DailyLessonResolver,
          newClasses: NewClassesResolver,
          favoriteClasses: FavoriteClassesResolver,
          actualChallenges: ActualChallengesResolver,
          challenges: ChallengesResolver,
        },
      },
      {
        path: 'page/:page',
        loadComponent: () => import('../layout/page/page.component').then(m => m.PageComponent),
        runGuardsAndResolvers: 'always',
        resolve: {
          page: PageResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'activity',
        loadChildren: () => import('./activities/activities.module').then(m => m.ActivitiesModule),
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: 'app/license/activate/:id',
    resolve: {
      license: LicenseResolver,
    },
    loadComponent: () => import('./redeem/redeem.component').then(m => m.RedeemComponent),
  },
  {
    path: 'redeem',
    loadComponent: () => import('./redeem/redeem.component').then(m => m.RedeemComponent),
  },
  {
    path: 'sale',
    canActivate: [SaleGuard],
    loadComponent: () => import('./dashboard.component').then(m => m.DashboardComponent),
  },
  {
    matcher: SaleMatcher,
    canActivate: [SaleGuard],
    loadComponent: () => import('./dashboard.component').then(m => m.DashboardComponent),
  },
  {
    path: 'welcome',
    canActivate: [WelcomeGuard],
    loadComponent: () => import('./dashboard.component').then(m => m.DashboardComponent),
  },
] as const;
