import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { StorageContextService } from '../../context/storage-context/storage-context.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  constructor(
    private router: Router,
    private storageContext: StorageContextService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const apiKey = route.queryParamMap.get('ApiKey') || route.queryParamMap.get('apiKey');

    if (apiKey) {
      this.storageContext.setItem('access_token', apiKey.replace(/\s/g, '+'));
    }

    if (this.storageContext.getItem('access_token')) {
      return true;
    }
    this.storageContext.setItem('back-url', state.url);
    this.router.navigate(['/auth/sign-in']);
    return false;
  }
}
