import { Injectable } from '@angular/core';
import { QuizImagesContextService } from '../../context/quiz-images-context/quiz-images-context.service';

@Injectable({ providedIn: 'root' })
export class QuizImagesResolver {
  constructor(private quizImagesContext: QuizImagesContextService) {}

  resolve(): void {
    const images = this.quizImagesContext.get();
    if (images) {
      return;
    }

    return this.quizImagesContext.init();
  }
}
