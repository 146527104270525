import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Lesson } from '../../dataset/Lesson';
import { LessonsApiService } from '../../api/lessons-api/lessons-api.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LessonsContextService } from '../../context/lessons-context/lessons-context.service';

@Injectable({ providedIn: 'root' })
export class LessonResolver {
  private readonly lessonsApiService = inject(LessonsApiService);
  private readonly lessonsContext = inject(LessonsContextService);

  resolve(route: ActivatedRouteSnapshot): Observable<Lesson> {
    return this.lessonsApiService
      .getLesson(route.params['lesson_id'])
      .pipe(tap((lesson: Lesson) => this.lessonsContext.nextCurrentLesson(lesson)));
  }
}
