<div
  #dialogContainer
  style="height: 0">
  <app-sprite-svg></app-sprite-svg>
</div>

<div
  #notificationContainer
  style="height: 0">
</div>

<router-outlet></router-outlet>

@if (!cookieNoticeDisabled) {
  <app-cookie-notice (disableCookieNotice)="disableCookieNotice()" />
}

@if (loading() || localeService.reloading()) {
  <div class="loading">
    <app-loading-logo
      class="block transition-opacity duration-1000"
      [class.opacity-100]="showLoadingLogo()"
      [class.opacity-0]="!showLoadingLogo()" />

    <app-loading />
  </div>
}
