import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Page } from '../../dataset/Page';
import { PagesApiService } from '../../api/pages-api/pages-api.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PageResolverService {
  constructor(private pagesApiService: PagesApiService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Page> {
    return this.pagesApiService.getPage(route.params['page']);
  }
}
