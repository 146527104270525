import { Routes } from '@angular/router';
import { CertificateResolver } from './modules/activate-certificate/resolvers/certificate.resolver';
import { CertificatePricesResolver } from './modules/certificate/resolvers/certificate-prices.resolver.';

export const GIFT_CERTIFICATE_ROUTES: Routes = [
  {
    path: 'gift',
    loadComponent: () => import('./gift.component').then(m => m.GiftComponent),
    children: [
      {
        path: 'certificate',
        loadComponent: () =>
          import('./modules/certificate/certificate.component').then(m => m.CertificateComponent),
        resolve: {
          certificatePrices: CertificatePricesResolver,
        },
      },
      {
        path: 'certificate/activate',
        loadComponent: () =>
          import('./modules/activate-certificate/activate-certificate.component').then(
            m => m.ActivateCertificateComponent
          ),
        resolve: {
          cert: CertificateResolver,
        },
      },
    ],
  },
] as const;
