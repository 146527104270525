import { ActivatedRouteSnapshot } from '@angular/router';
import { inject, Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { StorageContextService } from '../../../context/storage-context/storage-context.service';
import { AppConfiguration } from '../../../core/services/configuration/app.configuration';
import { SubscriptionContextService } from '../../../context/subscription-context/subscription-context.service';
import { UserStore } from '../../../stores/user/user.store';

@Injectable({ providedIn: 'root' })
export class SalePopupGuard {
  private readonly storageContext = inject(StorageContextService);
  private readonly userStore = inject(UserStore);
  private readonly appConfiguration = inject(AppConfiguration);
  private readonly subscriptionContext = inject(SubscriptionContextService);

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const apiKey = route.queryParamMap.get('ApiKey') || route.queryParamMap.get('apiKey');

    if (apiKey) {
      this.storageContext.setItem('access_token', apiKey.replace(/\s/g, '+'));
    }

    try {
      const user = await this.userStore.waitForLoading();

      const subscriptions = await lastValueFrom(this.subscriptionContext.retrieve());
      this.subscriptionContext.next(subscriptions.items);
      const hasYearly = subscriptions.items.some(s => s.duration_unit === 'year');

      if (hasYearly) {
        this.closePopup();
        return false;
      }
      return true;
    } catch (e) {
      this.storageContext.removeItem('access_token');
      this.closePopup();
      return false;
    }
  }

  private closePopup() {
    window.location.href = `${this.appConfiguration.webapp}/close-paywall`;
  }
}
